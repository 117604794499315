var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("div", { staticClass: "checklist-edit" }, [
        _c(
          "div",
          { staticClass: "step-box" },
          [
            _c("head-layout", {
              attrs: { "show-icon": false, "head-title": "处理进度" },
            }),
            _c(
              "el-steps",
              {
                staticStyle: {
                  "margin-top": "30px",
                  "align-items": "center",
                  height: "50%",
                },
                attrs: { direction: "vertical", active: _vm.active },
              },
              [
                _c("el-step", { attrs: { title: "上报" } }),
                _c("el-step", { attrs: { title: "评估" } }),
                _c("el-step", { attrs: { title: "整改" } }),
                _c("el-step", { attrs: { title: "验收" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "form-box" }, [
          _vm.prepare
            ? _c(
                "div",
                {},
                [
                  _c("head-layout", {
                    attrs: {
                      "head-title": _vm.headTitle,
                      "head-btn-options": _vm.headBtnOptions,
                    },
                    on: {
                      "head-cancel": _vm.headCancel,
                      "head-save": function ($event) {
                        return _vm.headSave(false)
                      },
                      "head-saveBack": function ($event) {
                        return _vm.headSave(true)
                      },
                      "head-revoke": _vm.handleRevoke,
                    },
                  }),
                  _c("div", { staticClass: "formContentBox" }, [
                    _c("div", { staticClass: "formMain" }, [
                      _c(
                        "div",
                        { staticStyle: { padding: "0 12px" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "dataForm",
                              attrs: {
                                model: _vm.dataForm,
                                "label-width": "150px",
                                rules: _vm.formType == "view" ? {} : _vm.rules,
                                disabled: _vm.formType == "view",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "隐患编号",
                                            prop: "hdCode",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: "",
                                              placeholder: "隐患编号自动生成",
                                            },
                                            model: {
                                              value: _vm.dataForm.hdCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "hdCode",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.hdCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "隐患地点",
                                            prop: "location",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                placeholder: "请填写隐患地点",
                                                title: _vm.dataForm.location,
                                              },
                                              model: {
                                                value: _vm.dataForm.location,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm,
                                                    "location",
                                                    $$v
                                                  )
                                                },
                                                expression: "dataForm.location",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "el-icon-map-location",
                                                    staticStyle: {
                                                      width: "54px",
                                                      "text-align": "center",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openMap()
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "项目名称",
                                            prop: "organizationName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            ref: "organizationName",
                                            attrs: {
                                              placeholder: "请选择被通知项目",
                                              title:
                                                _vm.dataForm.organizationName,
                                            },
                                            on: { focus: _vm.handleChangePrj },
                                            model: {
                                              value:
                                                _vm.dataForm.organizationName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "organizationName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.organizationName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "项目区域",
                                            prop: "area",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            ref: "areaName",
                                            attrs: {
                                              placeholder: "请选择项目区域",
                                              title: _vm.dataForm.area,
                                            },
                                            on: { focus: _vm.handleChangeArea },
                                            model: {
                                              value: _vm.dataForm.area,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "area",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.area",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  !_vm.isPrjUser
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "隐患接收人",
                                                prop: "assessmentUser",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                ref: "assessmentUserName",
                                                attrs: {
                                                  disabled: "",
                                                  title:
                                                    _vm.dataForm
                                                      .assessmentUserName,
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm
                                                      .assessmentUserName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm,
                                                      "assessmentUserName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm.assessmentUserName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "报告人",
                                            prop: "reportUserName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请选择报告人",
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.handleUserModeOpen()
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm.reportUserName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "reportUserName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.reportUserName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "报告部门",
                                            prop: "reportDeptName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              title:
                                                _vm.dataForm.reportDeptName,
                                              placeholder: "请选择报告部门",
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.handleDeptModeOpen()
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm.reportDeptName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "reportDeptName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.reportDeptName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "报告时间",
                                            prop: "reportDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetime",
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              placeholder: "请选择报告时间",
                                            },
                                            model: {
                                              value: _vm.dataForm.reportDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "reportDate",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.reportDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "隐患描述",
                                            prop: "hdDesc",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "flex-container" },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  type: "textarea",
                                                  autosize: { minRows: 2 },
                                                  "show-word-limit": "",
                                                  maxlength: "225",
                                                  placeholder: "请填写隐患描述",
                                                },
                                                model: {
                                                  value: _vm.dataForm.hdDesc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm,
                                                      "hdDesc",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "dataForm.hdDesc",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "hdDesc-mar" },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleDesc()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("常见隐患")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "隐患来源",
                                            prop: "sourceType",
                                          },
                                        },
                                        [
                                          _c("avue-select", {
                                            attrs: {
                                              props: {
                                                label: "dictValue",
                                                value: "dictKey",
                                              },
                                              placeholder: "请选择隐患来源",
                                              type: "tree",
                                              dic: _vm.HD_SOURCE_TYPE,
                                            },
                                            model: {
                                              value: _vm.dataForm.sourceType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "sourceType",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.sourceType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "隐患照片",
                                            prop: "treeData",
                                          },
                                        },
                                        [
                                          _vm.formType != "view"
                                            ? _c(
                                                "el-upload",
                                                {
                                                  ref: "uploadRef",
                                                  staticClass: "upload-demo",
                                                  staticStyle: {
                                                    "margin-bottom": "12px",
                                                    display: "flex",
                                                    "align-items": "center",
                                                  },
                                                  attrs: {
                                                    accept:
                                                      ".jpg,.mp4,.png,.jpeg",
                                                    action:
                                                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km",
                                                    "show-file-list": false,
                                                    "before-upload":
                                                      _vm.checkFileType,
                                                    "on-success":
                                                      _vm.handleAvatarSuccess,
                                                    headers: _vm.headers,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "small",
                                                        type: "primary",
                                                        icon: "el-icon-upload",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "点击上传\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "content" },
                                            _vm._l(
                                              _vm.treeData,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.id,
                                                    staticClass: "img-content",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getNodeClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: item.thumbnailLink,
                                                        alt: "",
                                                      },
                                                    }),
                                                    _vm.formType !== "view"
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-circle-close delete-icon",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.handleRemove(
                                                                item,
                                                                index
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.unassess || _vm.isPrjUser
            ? _c(
                "div",
                {},
                [
                  _c("head-layout", { attrs: { "head-title": "隐患评估" } }),
                  _c("div", { staticClass: "formContentBox" }, [
                    _c("div", { staticClass: "formMain" }, [
                      _c(
                        "div",
                        { staticStyle: { padding: "0 12px" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "dataForm1",
                              attrs: {
                                model: _vm.dataForm1,
                                "label-width": "150px",
                                disabled:
                                  !_vm.dataForm.hdStatus == "prepare" ||
                                  _vm.formType == "view",
                                rules:
                                  _vm.formType == "view" || !_vm.isPrjUser
                                    ? {}
                                    : _vm.rules1,
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  !_vm.isPrjUser &&
                                  _vm.dataForm1.passFlag != "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "隐患核实",
                                                prop: "passFlag",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: {
                                                    label: "y",
                                                    disabled: _vm.isPrjUser,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dataForm1.passFlag,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dataForm1,
                                                        "passFlag",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dataForm1.passFlag",
                                                  },
                                                },
                                                [_vm._v("核实通过")]
                                              ),
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: {
                                                    label: "n",
                                                    disabled: _vm.isPrjUser,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dataForm1.passFlag,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dataForm1,
                                                        "passFlag",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dataForm1.passFlag",
                                                  },
                                                },
                                                [_vm._v("核实不通过")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "隐患等级",
                                                prop: "hdLevel",
                                              },
                                            },
                                            [
                                              _c("avue-select", {
                                                attrs: {
                                                  props: {
                                                    label: "dictValue",
                                                    value: "dictKey",
                                                  },
                                                  placeholder: "请选择隐患等级",
                                                  type: "tree",
                                                  dic: _vm.hd_level,
                                                },
                                                model: {
                                                  value: _vm.dataForm1.hdLevel,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "hdLevel",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.hdLevel",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "隐患类型",
                                                prop: "hdType",
                                              },
                                            },
                                            [
                                              _c("avue-select", {
                                                attrs: {
                                                  props: {
                                                    label: "dictValue",
                                                    value: "dictKey",
                                                  },
                                                  placeholder: "请选择隐患类型",
                                                  type: "tree",
                                                  dic: _vm.hd_type,
                                                },
                                                model: {
                                                  value: _vm.dataForm1.hdType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "hdType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.hdType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  [
                                    _vm.dataForm1.hdType == "1"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "隐患因素",
                                                  prop: "hdFactors",
                                                },
                                              },
                                              [
                                                _c("avue-select", {
                                                  attrs: {
                                                    props: {
                                                      label: "dictValue",
                                                      value: "dictKey",
                                                    },
                                                    placeholder:
                                                      "请选择隐患因素",
                                                    type: "tree",
                                                    dic: _vm.hd_factors,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dataForm1.hdFactors,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dataForm1,
                                                        "hdFactors",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dataForm1.hdFactors",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.dataForm1.hdType == "1"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "隐患后果",
                                                  prop: "hdResult",
                                                },
                                              },
                                              [
                                                _c("avue-select", {
                                                  attrs: {
                                                    props: {
                                                      label: "dictValue",
                                                      value: "dictKey",
                                                    },
                                                    placeholder:
                                                      "请选择隐患后果",
                                                    type: "tree",
                                                    dic: _vm.hd_result,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dataForm1.hdResult,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dataForm1,
                                                        "hdResult",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dataForm1.hdResult",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.dataForm1.hdType == "2"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "违章类型",
                                                  prop: "hdBreakRules",
                                                },
                                              },
                                              [
                                                _c("avue-select", {
                                                  attrs: {
                                                    props: {
                                                      label: "dictValue",
                                                      value: "dictKey",
                                                    },
                                                    placeholder:
                                                      "请选择违章类型",
                                                    type: "tree",
                                                    dic: _vm.hd_break_rules,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dataForm1
                                                        .hdBreakRules,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dataForm1,
                                                        "hdBreakRules",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dataForm1.hdBreakRules",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                ],
                                2
                              ),
                              _c(
                                "el-row",
                                [
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "整改单位",
                                                prop: "rectifyDeptId",
                                              },
                                            },
                                            [
                                              !_vm.dataForm.hdStatus ==
                                                "prepare" ||
                                              _vm.formType == "view"
                                                ? _c("el-input", {
                                                    model: {
                                                      value:
                                                        _vm.dataForm1
                                                          .rectifyDeptName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.dataForm1,
                                                          "rectifyDeptName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dataForm1.rectifyDeptName",
                                                    },
                                                  })
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-container",
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "请选择整改单位",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.changeCompany,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.dataForm1
                                                                .rectifyDeptId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.dataForm1,
                                                                "rectifyDeptId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "dataForm1.rectifyDeptId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.rectifyDeptData,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.dutyUnit,
                                                                attrs: {
                                                                  value:
                                                                    item.dutyUnit,
                                                                  label:
                                                                    item.dutyUnitName,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass:
                                                            "hdDesc-mar",
                                                          attrs: {
                                                            size: "mini",
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleChangeOrg,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "选择组织&人员"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "整改人",
                                                prop: "rectifyUser",
                                              },
                                            },
                                            [
                                              !_vm.dataForm.hdStatus ==
                                                "prepare" ||
                                              _vm.formType == "view"
                                                ? _c("el-input", {
                                                    model: {
                                                      value:
                                                        _vm.dataForm1
                                                          .rectifyUserName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.dataForm1,
                                                          "rectifyUserName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dataForm1.rectifyUserName",
                                                    },
                                                  })
                                                : _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        filterable: "",
                                                        placeholder:
                                                          "请先选择整改单位",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeThreeUser,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.dataForm1
                                                            .rectifyUser,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dataForm1,
                                                            "rectifyUser",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dataForm1.rectifyUser",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.rectifyDeptData,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.dutyUser,
                                                          attrs: {
                                                            value:
                                                              item.dutyUser,
                                                            label:
                                                              item.dutyUserName,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "整改期限",
                                                prop: "rectifyLimitDate",
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                attrs: {
                                                  type: "date",
                                                  "picker-options":
                                                    _vm.pickerOptionsStart,
                                                  "value-format":
                                                    "yyyy-MM-dd HH:mm:ss",
                                                  placeholder: "请选择整改期限",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1
                                                      .rectifyLimitDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "rectifyLimitDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.rectifyLimitDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        {
                                          key: "rectifyRequest",
                                          attrs: { span: 24 },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "整改要求",
                                                prop: "rectifyRequest",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  type: "textarea",
                                                  maxlength: "500",
                                                  autosize: { minRows: 2 },
                                                  "show-word-limit": "",
                                                  placeholder: "请填写整改要求",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1
                                                      .rectifyRequest,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "rectifyRequest",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.rectifyRequest",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataForm1.passFlag == "n"
                                    ? _c(
                                        "el-col",
                                        { key: "remark", attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "备注",
                                                prop: "remark",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  type: "textarea",
                                                  autosize: { minRows: 2 },
                                                  "show-word-limit": "",
                                                  placeholder: "请填写备注",
                                                },
                                                model: {
                                                  value: _vm.dataForm1.remark,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "remark",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.remark",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.unrectify
            ? _c(
                "div",
                { staticClass: "unrectify" },
                [
                  _c(
                    "head-layout",
                    {
                      attrs: {
                        "head-title": "隐患整改",
                        "head-btn-options": [],
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "right" },
                        [
                          _c(
                            "el-dropdown",
                            [
                              _c(
                                "el-button",
                                { attrs: { type: "primary", size: "mini" } },
                                [
                                  _vm._v(
                                    "\n                整改历史\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(_vm.historyList, function (item) {
                                  return _c(
                                    "el-dropdown-item",
                                    {
                                      key: item.id,
                                      staticClass: "list-decoration",
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.handleDropdown(
                                            item,
                                            "unrectify"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.rectifyDate))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "formContentBox" }, [
                    _c("div", { staticClass: "formMain" }, [
                      _c(
                        "div",
                        { staticStyle: { padding: "0 12px" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "dataForm2",
                              attrs: {
                                model: _vm.dataForm2,
                                "label-width": "150px",
                                disabled: "",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "完成时间",
                                            prop: "rectifyDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "date",
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              placeholder: "请选择完成时间",
                                            },
                                            model: {
                                              value: _vm.dataForm2.rectifyDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm2,
                                                  "rectifyDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm2.rectifyDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "整改措施",
                                            prop: "remark",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              autosize: { minRows: 2 },
                                              "show-word-limit": "",
                                              placeholder: "请填写整改要求",
                                            },
                                            model: {
                                              value: _vm.dataForm2.remark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm2,
                                                  "remark",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm2.remark",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "整改结果",
                                            prop: "prjAs",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "content" },
                                            _vm._l(
                                              _vm.treeData4,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.id,
                                                    staticClass: "img-content",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getNodeClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          item.link ||
                                                          item.thumbnailLink,
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.finished
            ? _c(
                "div",
                {},
                [
                  _c(
                    "head-layout",
                    {
                      attrs: {
                        "head-title": "隐患验收",
                        "head-btn-options": [],
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "right" },
                        [
                          _c(
                            "el-dropdown",
                            [
                              _c(
                                "el-button",
                                { attrs: { type: "primary", size: "mini" } },
                                [
                                  _vm._v(
                                    "\n                验收历史\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  _vm.finishedHistoryList,
                                  function (item) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: item.id,
                                        staticClass: "list-decoration",
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleDropdown(
                                              item,
                                              "finished"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.acceptDate))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "formContentBox" }, [
                    _c("div", { staticClass: "formMain" }, [
                      _c(
                        "div",
                        { staticStyle: { padding: "0 12px" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "dataForm3",
                              attrs: {
                                model: _vm.dataForm3,
                                "label-width": "150px",
                                disabled: "",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "验收结果",
                                            prop: "acceptFlag",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: "y" },
                                              model: {
                                                value: _vm.dataForm3.acceptFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm3,
                                                    "acceptFlag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataForm3.acceptFlag",
                                              },
                                            },
                                            [_vm._v("核实通过")]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: "n" },
                                              model: {
                                                value: _vm.dataForm3.acceptFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm3,
                                                    "acceptFlag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataForm3.acceptFlag",
                                              },
                                            },
                                            [_vm._v("核实不通过")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "验收时间",
                                            prop: "acceptDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "date",
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              placeholder: "请选择完成时间",
                                            },
                                            model: {
                                              value: _vm.dataForm3.acceptDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm3,
                                                  "acceptDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm3.acceptDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "实际验收人",
                                            prop: "actualAcceptUserName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请选择实际验收人",
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm3
                                                  .actualAcceptUserName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm3,
                                                  "actualAcceptUserName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm3.actualAcceptUserName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "验收意见",
                                            prop: "acceptOpinion",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              autosize: { minRows: 2 },
                                              "show-word-limit": "",
                                              placeholder: "请填写验收意见",
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm3.acceptOpinion,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm3,
                                                  "acceptOpinion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm3.acceptOpinion",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "验收现场",
                                            prop: "prjAs",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "content" },
                                            _vm._l(
                                              _vm.treeData5,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.id,
                                                    staticClass: "img-content",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getNodeClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: item.thumbnailLink,
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择位置",
            "append-to-body": false,
            visible: _vm.mapBox,
            top: "7vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.mapBox = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _vm.mapBox
                ? _c("GaodeMap", {
                    ref: "GaodeMap",
                    on: {
                      selectAddress: _vm.selectAddress,
                      getLocation: _vm.getLocation,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.celMap } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleMapSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "部门选择", visible: _vm.deptShow1, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow1 = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            on: { "select-data": _vm.selectData1 },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("sortSetting", { ref: "sortSetting", on: { callback: _vm.callback } }),
      _c("selectProjectDialogVue", {
        ref: "selectProjectDialog",
        on: { changeProject: _vm.changeProject },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "项目区域选择",
            visible: _vm.areaDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.areaDialog = $event
            },
          },
        },
        [
          _c("prjArea", {
            ref: "prjArea",
            attrs: { dialogType: "" },
            on: { prjAreaCallback: _vm.prjAreaCallback },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "整改人选择",
            visible: _vm.userDialog,
            width: "80%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userDialog = $event
            },
          },
        },
        [
          _c("rectifyDataOrg", {
            ref: "rectifyDataOrg",
            attrs: { prjId: _vm.dataForm.organizationId },
            on: { callback: _vm.orgUserCallback },
          }),
        ],
        1
      ),
      _c("historyDialog", { ref: "historyDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }